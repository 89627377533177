import { storeToRefs } from 'pinia'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useUserInfluencerStore } from '~/stores/userInfluencer'

import {
  NudgeType,
  provideFetchCuratorProfileCompletion,
  providePatchCuratorIgnoredNudges,
} from '~/api-core/Curators/CuratorProfileCompletion'

import type {
  NudgeName,
  ProfileCompletionData,
} from '~/api-core/Curators/CuratorProfileCompletion'

export function useCuratorProfileProgression() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const profileProgressionPercentage = ref(0)
  const updateNudgeNames = ref<NudgeName[]>([])
  const ignoredNudgeNames = ref<NudgeName[]>([])
  const fillNudgeNames = ref<NudgeName[]>([])
  const totalStepCount = ref(10)
  const doesBackendAllowToShowProfileNudges = ref(false)

  const { id: INFLUENCER_ID } = storeToRefs(useUserInfluencerStore($pinia))

  const displayedNudgesType = computed<NudgeType>(() =>
    fillNudgeNames.value.length ? NudgeType.fill : NudgeType.update,
  )
  const nudgesToDisplay = computed<NudgeName[]>(() =>
    displayedNudgesType.value === NudgeType.fill
      ? fillNudgeNames.value
      : updateNudgeNames.value,
  )
  const shouldShowProfileNudges = computed<boolean>(
    () =>
      doesBackendAllowToShowProfileNudges.value &&
      !!nudgesToDisplay.value.length,
  )

  onMounted(async () => {
    const fetchCuratorProfileCompletion =
      provideFetchCuratorProfileCompletion(coreFetch)
    const {
      fill_section_nudges,
      ignored_nudges,
      up_to_date_nudges,
    }: ProfileCompletionData = await fetchCuratorProfileCompletion(
      INFLUENCER_ID.value,
    )
    profileProgressionPercentage.value = fill_section_nudges.score
    // N.B: the ignored nudges have already been filtered out on the backend
    fillNudgeNames.value = fill_section_nudges.nudges
    doesBackendAllowToShowProfileNudges.value =
      !!fill_section_nudges.nudges.length
    ignoredNudgeNames.value = ignored_nudges
    updateNudgeNames.value = up_to_date_nudges
  })

  function handleIgnoreNudgeName(): void {
    if (displayedNudgesType.value === NudgeType.update)
      return ignoreProfileUpdate()

    ignoreProfileFill()
  }

  function ignoreProfileFill(): void {
    const newIgnoredNudgeName = fillNudgeNames.value.shift()

    if (!newIgnoredNudgeName) return

    ignoredNudgeNames.value.push(newIgnoredNudgeName)
    saveIgnoredNudges()
  }

  function ignoreProfileUpdate(): void {
    updateNudgeNames.value.shift()
  }

  function saveIgnoredNudges(): void {
    const patchCuratorIgnoredNudges =
      providePatchCuratorIgnoredNudges(coreFetch)
    patchCuratorIgnoredNudges(
      INFLUENCER_ID.value,
      ignoredNudgeNames.value,
      !fillNudgeNames.value.length,
    )
  }

  return {
    shouldShowProfileNudges,
    profileProgressionPercentage,
    nudgesToDisplay,
    displayedNudgesType,
    totalStepCount,
    handleIgnoreNudgeName,
  }
}
